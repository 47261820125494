import { useContext, useEffect, useRef } from 'react';
import ReferenceContainer from '../ReferenceContainer/ReferenceContainer';
import { HiPayProvider } from '../../contexts/hipay';
import { HostedReferenceProvider } from '../../contexts/hostedReferenceInstance';
import { loadHiPay } from '../../utils/hipayLoader';
import { intlContext, translations } from '../../contexts/intl';
import { storeContext } from '../../contexts/store';
import {
  StyledBackground,
  StyledContentContainer,
  StyledPageContainer
} from '../Page/Page';

const { useParams, useNavigate } = require('react-router-dom');

const PageReference = () => {
  const navigate = useNavigate();
  const { dispatchStore } = useContext(storeContext);
  const { dispatchLocale } = useContext(intlContext);
  const { payment_product, transaction_id } = useParams();

  let forwardUrl = useRef(null);
  let hipayPromise = useRef(null);
  useEffect(() => {
    if (['multibanco', 'mooney'].includes(payment_product)) {
      const query = new URLSearchParams(window.location.search);
      forwardUrl.current = query.get('forwardUrl');

      let lang = window.navigator.language.slice(0, 2) || 'en';
      let queryLang = query.get('lang');
      if (translations.hasOwnProperty(queryLang)) {
        lang = queryLang;
      }

      dispatchLocale({
        type: 'UPDATE_INTL',
        language: lang
      });

      hipayPromise.current = loadHiPay({
        username: 'u',
        password: 'p',
        lang,
        environment: process.env.REACT_APP_HIPAY_ENV
      });

      let paymentProduct = payment_product;
      const referenceToPay = {};
      if (payment_product === 'mooney') {
        paymentProduct = 'sisal';
        referenceToPay.reference = transaction_id;
        referenceToPay.barCode = query.get('barCode');
      } else {
        referenceToPay.reference = query.get('reference');
        referenceToPay.entity = query.get('entity');
        referenceToPay.amount = query.get('amount');
        referenceToPay.expirationDate = query.get('expirationDate');
      }

      dispatchStore({
        type: 'UPDATE_PAGE_STATUS',
        reference: referenceToPay,
        payment_product: paymentProduct
      });
    } else {
      navigate('/404');
    }
  }, [
    dispatchLocale,
    dispatchStore,
    navigate,
    payment_product,
    transaction_id
  ]);

  return (
    <HiPayProvider hipay={hipayPromise.current}>
      <HostedReferenceProvider>
        <StyledPageContainer>
          <StyledContentContainer>
            <ReferenceContainer
              forwardUrl={forwardUrl.current}
              external={true}
            />
          </StyledContentContainer>
          <StyledBackground />
        </StyledPageContainer>
      </HostedReferenceProvider>
    </HiPayProvider>
  );
};

export default PageReference;
