import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

import { useHipay } from './hipay';
import { storeContext } from './store';
import { useNavigate } from 'react-router-dom';

const HostedReferenceInstanceContext = createContext();

export const HostedReferenceProvider = ({ children }) => {
  const navigate = useNavigate();
  const { store } = useContext(storeContext);
  const hipay = useHipay();

  const [hostedReferenceInstance, setHostedReferenceInstance] = useState(null);

  // Function to init an instance
  // Change when HiPay is loaded or when config change from payload
  const initHostedReferenceInstance = useCallback(
    (isExternal) => {
      if (!hipay) {
        return;
      }

      let newInstance;
      try {
        // Create Reference
        newInstance = hipay.createReference(store.payment_product, {
          selector: 'hosted-form',
          ...store.reference
        });
      } catch (err) {
        if (isExternal) {
          navigate('/404');
        } else {
          throw err;
        }
      }

      setHostedReferenceInstance(newInstance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hipay, navigate]
  );

  const hostedRefInstance = useMemo(
    () => ({
      hostedReferenceInstance,
      initHostedReferenceInstance
    }),
    [hostedReferenceInstance, initHostedReferenceInstance]
  );

  // Render provider with instance, init & destroy exposed
  return (
    <HostedReferenceInstanceContext.Provider value={hostedRefInstance}>
      {children}
    </HostedReferenceInstanceContext.Provider>
  );
};

// Hook function to use Hosted Instance Reference from context
export function useHostedReferenceInstance() {
  const context = useContext(HostedReferenceInstanceContext);

  if (!context) {
    throw new Error(
      'useHostedReferenceInstance must be used within a HostedReferenceProvider'
    );
  }

  return context;
}

HostedReferenceProvider.propTypes = {
  children: PropTypes.any
};
