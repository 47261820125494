// import axios from 'axios';
//
// const log_api_url = '';

// If env = production or stage
// Init logger

const initErrorLogger = () => {
  window.onerror = (...params) => {
    console.log('TO LOG', params);

    // sendErrorLog(params);
  };
};

initErrorLogger();

// const sendErrorLog = error => {
//   axios.post(log_api_url, error);
// };
