import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { redirectPage } from '../../utils/redirect';

import { useHostedReferenceInstance } from '../../contexts/hostedReferenceInstance';

const ReferenceContainer = (props) => {
  const { initHostedReferenceInstance } = useHostedReferenceInstance();

  useEffect(() => {
    initHostedReferenceInstance(props.external);
  }, [initHostedReferenceInstance, props.external]);

  const handleBackClick = () => {
    redirectPage(props.forwardUrl);
  };

  const handlePrintClick = () => {
    window.print();
  };

  return (
    <StyledReferenceContainer>
      <div className="form-container">
        <div id="hosted-form" data-testid="hosted-form" />
      </div>
      <div className="buttons-container">
        {props.forwardUrl && (
          <Button
            id="back-button"
            data-testid="back-button"
            aria-label="back-button"
            variant="outlined"
            size="large"
            color="success"
            onClick={handleBackClick}
          >
            <FormattedMessage id="back-to-website" />
          </Button>
        )}
        <Button
          id="print-button"
          data-testid="print-button"
          aria-label="print-button"
          variant="contained"
          size="large"
          color="success"
          onClick={handlePrintClick}
        >
          <FormattedMessage id="print" />
        </Button>
      </div>
    </StyledReferenceContainer>
  );
};

const StyledReferenceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin: auto;
  height: 100%;

  #hosted-form {
    padding: 0 24px;
  }

  .form-container {
    overflow: auto;
  }

  .buttons-container {
    height: 20%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-top: 32px;
    justify-content: center;
  }

  #back-button {
    margin-right: 16px;
  }

  iframe {
    height: 99%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .buttons-container {
      margin-top: 16px;
    }

    #back-button {
      width: 100%;
      margin: 0;
    }

    #print-button {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media print {
    .form-container,
    .form-container * {
      visibility: visible;
    }
  }

  @page {
    size: 21cm 29.7cm;
    margin: 5mm 5mm 5mm 5mm;
  }
`;

ReferenceContainer.propTypes = {
  forwardUrl: PropTypes.string,
  // true if reference is loaded from external referrer
  external: PropTypes.bool
};

export default ReferenceContainer;
