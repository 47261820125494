import styled from 'styled-components/macro';

import { usePayload } from '../../contexts/store';
import { useTheme } from '../../contexts/theme';

import { FormattedNumber, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

const HeaderComponent = ({ className }) => {
  const theme = useTheme();
  const payload = usePayload();

  if (!payload || !payload.request) {
    return null;
  }

  return (
    <div className={className}>
      <div className="left-part">
        {theme?.merchant?.logo && (
          <img
            id="merchant_logo"
            data-testid="merchant_logo"
            src={theme.merchant.logo}
            alt="merchant_logo"
          />
        )}
        {theme?.merchant && !theme.merchant.logo && (
          <Typography
            variant="h2"
            data-testid="merchant_display_name"
            id="merchant_name"
          >
            {theme.merchant.merchant_display_name}
          </Typography>
        )}
      </div>

      <div className="right-part">
        <div>
          <Typography variant="body2" color="textSecondary">
            #{payload.request.orderid}
          </Typography>
        </div>
        <div className="amount-container">
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="order-amount" />
          </Typography>
          <Typography variant="h1" className="amount">
            <FormattedNumber format="currency" value={payload.request.amount} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

const Header = styled(HeaderComponent)`
  display: flex;
  flex-direction: row;

  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: solid 1px #dee1e6;

  max-height: 105px;
  overflow: hidden;

  @media print {
    visibility: visible;

    * {
      visibility: visible;
    }
  }

  .left-part {
    display: flex;
    overflow: hidden;
    margin-right: 24px;

    #merchant_name {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    #merchant_logo {
      object-fit: contain;
      object-position: left;
      height: auto;
      width: 100%;

      max-height: 100%;
    }
  }

  .right-part {
    flex: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;

    text-align: right;

    p {
      margin-bottom: 6px;
    }

    .amount {
      color: black;
      font-size: 26px;
      font-weight: 900;
    }

    @media print {
      p {
        color: black;
      }

      .amount-container * {
        visibility: hidden;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: 1921px) {
    max-height: 160px;
    min-height: 120px;
    padding-bottom: 32px;
    margin-bottom: 32px;

    .MuiTypography-colorTextSecondary {
      font-size: 16px;
    }

    .right-part .amount {
      font-size: 32px;
    }
  }
`;
export default Header;
