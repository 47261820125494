import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

import CheckIcon from '../CheckIcon/CheckIcon';
import CrossIcon from '../CrossIcon/CrossIcon';
import Spinner from '../Spinner/Spinner';

import { redirectPage } from '../../utils/redirect';

const REDIRECT_DURATION = 5000;

const PageStatusComponent = (props) => {
  // Redirect user to forward url in props
  const redirect = () => {
    redirectPage(props.forward_url);
  };

  // If forward_url, redirect user after a timer
  if (props.forward_url) {
    setTimeout(() => {
      redirect();
    }, REDIRECT_DURATION);
  }

  return (
    <div className={props.className}>
      <Typography variant="subtitle1" className="text-status">
        <FormattedMessage id={`status-text-${props.status}`} />
      </Typography>

      {props.state === 'loading' && <Spinner />}
      {props.state === 'accepted' && <CheckIcon />}
      {props.state === 'rejected' && <CrossIcon />}

      {props.textSecondary && (
        <Typography variant="body1" className="text-secondary">
          <FormattedMessage id={`status-text-${props.textSecondary}`} />
        </Typography>
      )}

      {props.forward_url && (
        <Typography
          variant="body1"
          color="textSecondary"
          className="text-redirect"
        >
          <FormattedMessage
            id="redirect-message"
            values={{
              timer: (
                <span className="link" onClick={redirect}>
                  <FormattedMessage
                    id="timer-message"
                    values={{ x: REDIRECT_DURATION / 1000 }}
                  />
                </span>
              )
            }}
          />
        </Typography>
      )}
    </div>
  );
};

const PageStatus = styled(PageStatusComponent)`
  text-align: center;

  .text-status {
    margin-top: 46px;
    margin-bottom: 32px;

    color: ${({ state, theme }) =>
      state === 'accepted'
        ? theme.palette.success.main
        : state === 'rejected'
        ? theme.palette.error.main
        : 'inherit'};

    @media screen and (min-width: 1921px) {
      margin-top: 92px;
    }
  }

  .text-secondary {
    margin-top: 32px;
  }

  .text-redirect {
    margin-top: 64px;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

PageStatus.propTypes = {
  state: PropTypes.oneOf(['loading', 'accepted', 'rejected']).isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default PageStatus;
