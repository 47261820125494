import './ErrorLogger/ErrorLogger';

import { createRoot } from 'react-dom/client';

import App from './App';

console.log(`HiPay Payment page v${process.env.REACT_APP_VERSION}`);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
