import { useRef, useState, createContext, useMemo, useContext } from 'react';

const hipayContext = createContext();
const { Provider } = hipayContext;

// Validate HiPay instance
const validateHipay = (hipayObj) => {
  if (
    hipayObj === null ||
    (typeof hipayObj === 'object' && typeof hipayObj.create === 'function')
  ) {
    return hipayObj;
  } else {
    throw new Error('HiPay instance is incorrect');
  }
};

const parseHipayProp = (hipayProp) => {
  // If is Promise
  if (
    hipayProp !== null &&
    typeof hipayProp === 'object' &&
    typeof hipayProp.then === 'function'
  ) {
    return {
      type: 'async',
      hipayPromise: Promise.resolve(hipayProp).then(validateHipay)
    };
  }

  // Else validate hipayProp to check if real HiPay instance
  const hipay = validateHipay(hipayProp);

  if (hipay === null) {
    return { type: 'empty' };
  }

  return { type: 'sync', hipay };
};

export const HiPayProvider = ({ hipay: rawHipayProp, children }) => {
  const loaded = useRef(false);

  const parsed = useMemo(() => parseHipayProp(rawHipayProp), [rawHipayProp]);

  const [ctx, setContext] = useState({
    hipay: null
  });

  if (!loaded.current) {
    if (parsed.type === 'sync') {
      loaded.current = true;
      setContext({
        hipay: parsed.hipay
      });
    }

    if (parsed.type === 'async') {
      loaded.current = true;
      parsed.hipayPromise.then((hipay) => {
        setContext({
          hipay
        });
      });
    }
  }

  return <Provider value={ctx}>{children}</Provider>;
};

export const useHipay = () => {
  const ctx = useContext(hipayContext);
  return ctx.hipay;
};
