import { createTheme as createMuiTheme } from '@mui/material/styles';
import { darken } from '@mui/material';

import WebFont from 'webfontloader';

// Themes palettes
import palettes from './palettes.json';

// Templates
import material from './material.json';

const templates = {
  material
};

// Create new Material UI theme
export const createTheme = (
  template = 'material',
  theme = 'hipay',
  customization = {}
) => {
  // Get default theme object
  let themeObject = getTheme(template, theme);

  // Customize theme object
  themeObject = customizeTheme(themeObject, customization);

  // Return material UI designed theme
  return createMuiTheme(themeObject);
};

// Get theme from config files
const getTheme = (template = 'material', theme = 'hipay') => {
  return {
    ...templates[`${template}`],
    palette: {
      ...palettes[`${theme}`]
    }
  };
};

// Add customization to a theme
const customizeTheme = (theme, customization) => {
  let customizedTheme = { ...theme };

  // Customize color palette
  if (customization?.color) {
    // Primary
    if (customization.color?.primary) {
      // If primary != HiPay primary color
      // Delete contrastText to calculate it automatically
      // Else keep white
      if (customization.color.primary !== palettes[`hipay`].primary.main) {
        customizedTheme.isCustomTheme = true;
        delete customizedTheme.palette.primary.contrastText;
      }

      customizedTheme.palette.primary.main = customization.color.primary;
    }

    // Secondary
    if (customization.color?.secondary) {
      customizedTheme.palette.secondary.main = customization.color.secondary;
    }
  }

  // Darken primary color for hover buttons states
  customizedTheme.palette.primary.dark = darken(
    customizedTheme.palette.primary.main,
    0.05
  );

  // Customize fonts
  if (customization?.font) {
    customizeFont(customizedTheme, customization.font);
  }

  // Customize content
  if (customization?.content) {
    customizedTheme.content = { ...customization.content };
  }

  // Customize merchant
  if (customization?.merchant) {
    customizedTheme.merchant = { ...customization.merchant };
  }
  return customizedTheme;
};

// Customize theme font
// Fonts can be load from web with Google Fonts
const customizeFont = (theme, font) => {
  const { type, family } = font;

  if (type && family) {
    // Load font
    if (type === 'google') {
      // Load GoogleFont
      WebFont.load({
        google: {
          families: [family]
        }
      });
    }

    // Add font to theme in first position
    theme.typography.fontFamily.unshift(family);
  }
};
