export const redirectPage = (forward_url) => {
  // If iframe redirect parent page
  if (window.top !== window.self) {
    try {
      window.top.location.href = forward_url;
    } catch (error) {
      window.location.assign(forward_url);
    }
  } else {
    window.location.assign(forward_url);
  }
};
