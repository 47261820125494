import { createContext, useReducer, useContext } from 'react';

import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import GlobalStyles from '../theme/global-styles';

import { createTheme } from '../theme/theme-toolbox';

const themeContext = createContext();
const { Provider } = themeContext;

const ThemeProvider = ({ initialState = {}, children }) => {
  const [theme, dispatchTheme] = useReducer(reducer, undefined, () =>
    createTheme(
      initialState.template,
      initialState.theme,
      initialState.customization
    )
  );

  return (
    <Provider value={{ theme, dispatchTheme }}>
      <GlobalStyles theme={theme} />
      <MaterialThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MaterialThemeProvider>
    </Provider>
  );
};

const reducer = (state, action) => {
  if (action.type === 'UPDATE_CUSTOMIZATION') {
    return createTheme(undefined, undefined, action.customization);
  }
  return state;
};

export const useTheme = () => {
  const { theme } = useContext(themeContext);

  return theme;
};

export { themeContext, ThemeProvider };
