import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Button from '@mui/material/Button';

import { useTheme } from '../../contexts/theme';

const PayButtonComponent = (props) => {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <Button
        id="pay-button"
        data-testid="pay-button"
        aria-label="pay-button"
        variant="contained"
        size="large"
        color={theme.isCustomTheme ? 'primary' : 'success'}
        fullWidth
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <FormattedMessage id="pay" />{' '}
        <FormattedNumber format="currency" value={props.amount} />
      </Button>
    </div>
  );
};

const PayButton = styled(PayButtonComponent).attrs({
  className: 'pay-button-container'
})`
  height: 48px;
  max-width: 640px;

  text-align: center;

  padding: 0 10px;
  margin: auto;
  margin-top: 32px;

  #pay-button {
    max-width: 500px;
  }

  button {
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0 34px;
  }

  @media screen and (min-width: 1921px) {
    padding: 0 10px;

    #pay-button {
      max-width: 100%;
    }
  }
`;

PayButton.propTypes = {
  disabled: PropTypes.bool,
  amount: PropTypes.string,
  onClick: PropTypes.func
};

PayButton.defaultProps = {
  disabled: false,
  onClick: () => {}
};

export default PayButton;
