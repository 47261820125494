/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components/macro';
import { flushSync } from 'react-dom';

import Status from '../../Enum/PageStatus';

import { usePayload, storeContext } from '../../contexts/store';
import { useHostedInstance } from '../../contexts/hostedInstance';

import { processOrder } from '../../utils/api';
import { redirectPage } from '../../utils/redirect';

import FormPaymentTitle from '../../components/FormPaymentTitle/FormPaymentTitle';
import PayButton from '../../components/PayButton/PayButton';
import PageStatus from '../../components/PageStatus/PageStatus';
import InlineError from '../../components/InlineError/InlineError';

const FormContainer = () => {
  const { store, dispatchStore } = useContext(storeContext);
  const payload = usePayload();
  const { hostedInstance, initHostedInstance, destroyHostedInstance } =
    useHostedInstance();

  // Loading state
  const [isLoading, setLoading] = useState(false);
  // Boolean to display generic error in case of retry
  const [hasError, setError] = useState(false);
  // Add numer of retries remaining
  const [retries, setRetries] = useState(null);

  // Init hostedInstance when itself change. Callback function is changing when config change or when hipay is loaded
  useEffect(() => {
    initHostedInstance();
  }, [initHostedInstance]);

  useEffect(() => {
    dispatchStore({
      type: 'LOADING',
      isLoading
    });
  }, [dispatchStore, isLoading]);

  /**
   * Process payment
   * @param {Object} paymentData
   */
  const processPayment = useCallback(
    async (paymentData) => {
      try {
        // Call API transaction to process order
        const result = await processOrder(
          payload.token_auth,
          store.token_url,
          paymentData
        );

        // If status forwarding, go to forward_url
        if (result.status === 'forwarding') {
          redirectPage(result.forward_url);
          return;
        }

        dispatchStore({
          type: 'UPDATE_PAGE_STATUS',
          status: result.hasOwnProperty('reference')
            ? 'pending_reference'
            : result.status,
          forward_url: result.forward_url,
          reference: result.hasOwnProperty('reference')
            ? result.reference
            : null,
          payment_product: paymentData.payment_product
        });
      } catch (err) {
        // Check if internal error
        if (err.status === 'internal') {
          dispatchStore({
            type: 'UPDATE_PAGE_STATUS',
            status: 'internal-error',
            forward_url: err.forward_url
          });
          return;
        }

        // Check if page has expired
        if (err?.error?.code === 'paymentservice.page_expired') {
          dispatchStore({
            type: 'UPDATE_PAGE_STATUS',
            status: Status.EXPIRED,
            forward_url: null
          });
          return;
        }

        // Check if can retry payment (retries > 0)
        if (err.hasOwnProperty('retries') && err.retries > 0) {
          flushSync(() => {
            // Display error
            setError(true);
            // Update retries remaining
            setRetries(err.retries);
            // Hide loading
            setLoading(false);
          });
          // Init new Hosted Instance
          initHostedInstance();
          return;
        }

        // Else set status to critical error (3)
        dispatchStore({
          type: 'UPDATE_PAGE_STATUS',
          status: Status.FAILED,
          forward_url: err.forward_url
        });
      }
    },
    [dispatchStore, initHostedInstance, payload.token_auth, store.token_url]
  );

  useEffect(() => {
    async function process() {
      if (store.paymentData) {
        dispatchStore({
          type: 'GET_PAYMENT_DATA',
          paymentData: null
        });

        if (!store.paymentData.isError && store.paymentData.result) {
          // Go into loading state
          setLoading(true);

          // Reset formValidity to false in case of payment fail
          dispatchStore({
            type: 'UPDATE_FORM_VALIDITY',
            formValidity: false
          });

          // Destroy current instance
          destroyHostedInstance();

          await processPayment(store.paymentData.result);
        } else if (store.paymentData.payment_product === 'paypal') {
          // Go into loading state
          setLoading(true);
          // Destroy current instance
          destroyHostedInstance();

          store.paymentData.paypal_order_id = store.paymentData.orderID;

          await processPayment(store.paymentData);
        }
      }
    }
    process();
  }, [destroyHostedInstance, dispatchStore, processPayment, store]);

  // On payment button click
  const handleClick = async () => {
    // Return if form invalid
    if (!store.formValidity) {
      return;
    }

    // Go into loading state
    setLoading(true);

    // Reset formValidity to false in case of payment fail
    dispatchStore({
      type: 'UPDATE_FORM_VALIDITY',
      formValidity: false
    });

    // Get payment data from Hosted Instance
    const paymentData = await hostedInstance.getPaymentData();

    // Destroy current instance
    destroyHostedInstance();

    await processPayment(paymentData);
  };

  return (
    <StyledFormContainer>
      {isLoading ? (
        <PageStatus state="loading" status="loading" />
      ) : (
        <div className="form-container">
          <FormPaymentTitle />
          <div id="hosted-form" data-testid="hosted-form" />
          {hasError && <InlineError retries={retries} />}
          {(hostedInstance?.needPayButton ||
            (!hostedInstance?.isList &&
              hostedInstance?.currentPaymentInstance?.needPayButton)) && (
            <PayButton
              amount={payload.request.amount}
              disabled={!store.formValidity}
              onClick={handleClick}
            />
          )}
        </div>
      )}
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled('div')`
  overflow: auto;
  height: inherit;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    #hosted-form {
      padding: 0 24px;
    }
  }

  .HiPayField--focused + .hipay-field-label {
    color: ${({ theme }) => theme.palette.secondary.main} !important;
  }

  @media screen and (min-width: 1921px) {
    .hipay-field-container:not(.hipay-field-radio-container) {
      height: 55px;
    }

    .hipay-field {
      height: 25px;
    }

    .hipay-field-label {
      font-size: 18px;
    }

    .hipay-form-container {
      max-width: 640px;
    }

    .hipay-form-row {
      margin: 0 0 30px 0;
    }
  }

  .HiPayField--focused + .hipay-field-label + .hipay-field-baseline {
    border-bottom: solid 1px ${({ theme }) => theme.palette.secondary.main};
  }

  .cancel-container {
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel-button {
    margin: auto;
  }
`;

export default FormContainer;
