import { useContext } from 'react';
import styled from 'styled-components/macro';

import PageStatus from '../../Enum/PageStatus';

import { ReactComponent as SecuredLogo } from './icons-security.svg';
import { ReactComponent as HiPayLogo } from './hipay-logo.svg';
import VisaMastercardSecurityLogo from './visa-mastercard-security.png';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { usePayload, storeContext } from '../../contexts/store';
import { redirectPage } from '../../utils/redirect';
import CloseIcon from '../CloseIcon/CloseIcon';

const FooterComponent = ({ className, status }) => {
  const { store } = useContext(storeContext);
  const payload = usePayload();

  return (
    <div className={className}>
      <div className="cancel-container">
        {payload.request.display_cancel_button === 1 &&
          payload.request.cancel_url &&
          [PageStatus.PENDING, PageStatus.FORWARDING].includes(status) &&
          !store.isLoading && (
            <Button
              className="cancel-button"
              variant="text"
              color="secondary"
              data-testid="cancel-button"
              aria-label="cancel-button"
              onClick={() => {
                redirectPage(payload.request.cancel_url);
              }}
            >
              <Typography
                className="text"
                variant="body2"
                color="textSecondary"
              >
                <CloseIcon />
                <FormattedMessage id="cancel" />
              </Typography>
            </Button>
          )}
      </div>
      <div className="logos">
        <div className="hipay-secured">
          <SecuredLogo className="secured-logo" />
          <Typography className="text" variant="body2" color="textSecondary">
            <FormattedMessage id="secured-by" />
          </Typography>
          <HiPayLogo className="hipay-logo" />
        </div>
        {
          // We only display Visa and Mastercard logo if visa or mastercard is in payment means
          (!payload.request.payment_product_list ||
            payload.request.payment_product_list.includes('visa') ||
            payload.request.payment_product_list.includes('mastercard')) && (
            <img
              src={VisaMastercardSecurityLogo}
              className="visa-mastercard-security-logo"
              alt="visa_mastercard_security_logo"
            />
          )
        }
      </div>
    </div>
  );
};

const Footer = styled(FooterComponent)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cancel-container {
    .text {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-transform: none;
      color: #737373;
    }
  }

  .logos {
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }

  .hipay-secured {
    display: flex;
    align-items: flex-start;

    .hipay-logo {
      height: 18px;
      width: 50px;
    }
  }

  .text {
    margin: 0 4px;
    line-height: 16px;
  }

  .visa-mastercard-security-logo {
    height: 48px;
    width: 134px;
  }

  .secured-logo {
    height: 12px;
    width: 12px;
  }

  @media screen and (min-width: 1921px) {
    .text {
      margin: 0 4px;
      line-height: 18px;
    }

    .visa-mastercard-security-logo {
      height: 60px;
    }

    .MuiTypography-colorTextSecondary {
      font-size: 16px;
    }

    .hipay-logo {
      height: 24px;
      width: 45px;
    }

    .secured-logo {
      height: 16px;
      width: 16px;
    }
  }
`;

export default Footer;
