import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { useHostedInstance } from '../../contexts/hostedInstance';

import Typography from '@mui/material/Typography';

const FormPaymentTitle = (props) => {
  const { hostedConfig } = useHostedInstance();

  if (!hostedConfig || hostedConfig.type !== 'card') {
    return null;
  }

  return (
    <div className={props.className}>
      <Typography className="text" variant="h5">
        <FormattedMessage id="payment-card" />
      </Typography>
    </div>
  );
};

const StyledFormPaymentTitle = styled(FormPaymentTitle)`
  margin: auto;
  max-width: 520px;
  margin-top: 12px;
  margin-bottom: 32px;
`;

export default StyledFormPaymentTitle;
