import styled from 'styled-components/macro';

import { ReactComponent as Logo } from '../../containers/Page404/404.svg';
import Spinner from '../Spinner/Spinner';

const Logo404 = ({ className }) => {
  return (
    <div className={className}>
      <div className="spinner-container">
        <Spinner
          className="spinner"
          width="100%"
          height="100%"
          animate={false}
        />
      </div>
      <div className="logo-container">
        <Logo className="logo-404" alt="404" />
      </div>
    </div>
  );
};
const StyledLogo404 = styled(Logo404)`
  position: relative;

  .logo-container {
    position: relative;
    width: 100%;
    height: auto;

    margin: 16px auto;
    padding: 32px 52px;
  }

  .logo-404 {
    width: 100%;
    height: auto;
  }

  .spinner-container {
    position: absolute;

    width: 45%;
    padding-bottom: 45%;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .spinner {
      transform: rotate(135deg);
    }
  }
`;

export default StyledLogo404;
