import { ReactComponent as Check } from './CrossIcon.svg';
import styled from 'styled-components/macro';

const CheckIcon = styled(Check)`
  width: ${(props) => props.width || '120px'};
  height: ${(props) => props.height || '120px'};

  .circle_fill {
    fill: ${({ theme }) => theme.palette.error.main};
  }

  .circle_stroke {
    fill: none;
    stroke: ${({ theme }) => theme.palette.error.main};

    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .cross {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation-direction: reverse;

    &.cross_1 {
      animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards,
        scale 0.6s ease-in-out 1s both;
    }
    &.cross_2 {
      animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards,
        scale 0.6s ease-in-out 1s both;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.2, 1.2, 1);
    }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

export default CheckIcon;
