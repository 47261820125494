import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo
} from 'react';

import { storeContext, usePayload } from './store';
import { useHipay } from './hipay';
import { getHostedOptions } from '../utils/requestToHostedOptions';

const HostedInstanceContext = createContext();

export const HostedInstanceProvider = ({ children }) => {
  const hipay = useHipay();
  const payload = usePayload();

  // Hosted Instance (Hosted Fields or Hosted Payments)
  const [hostedInstance, setInstance] = useState(null);
  const [hostedConfig, setHostedConfig] = useState(null);

  // Get dispatch function
  const { dispatchStore } = useContext(storeContext);

  // Get Hosted Fields/Payments config form request
  useEffect(() => {
    const getHostedConfig = async () => {
      if (hipay && !hostedConfig) {
        const { type, options } = await getHostedOptions(
          payload.request,
          payload.customization,
          hipay.client
        );
        setHostedConfig({
          type,
          options: {
            selector: 'hosted-form',
            template: 'auto',
            ...options
          }
        });
      }
    };
    getHostedConfig();
  }, [hipay, hostedConfig, payload]);

  // Function to init an instance
  // Change when HiPay is loaded or when config change from payload
  const initHostedInstance = useCallback(() => {
    if (!hipay || !hostedConfig) {
      return;
    }

    // Create new instance
    let newInstance = hipay.create(hostedConfig.type, hostedConfig.options);

    // Set new state with this instance
    setInstance(newInstance);
  }, [hipay, hostedConfig]);

  // Function to destroy the instance
  const destroyHostedInstance = useCallback(() => {
    // If already have an instance, destroy it
    if (hostedInstance) {
      // TODO: Fix SDK JS destroy function
      try {
        hostedInstance.destroy();
      } catch (e) {
        console.error(e);
      }
    }

    // reset instance
    setInstance(null);
  }, [hostedInstance]);

  // Init useful events from Hosted Instance
  const initHostedInstanceEvents = useCallback(() => {
    if (hostedInstance) {
      // Dispatch form validity to store
      const dispatchValidity = (validity) => {
        dispatchStore({
          type: 'UPDATE_FORM_VALIDITY',
          formValidity: validity
        });
      };

      // Hosted Fields instances has type property, not Hosted Payments
      if (hostedInstance.hasOwnProperty('type')) {
        // Listen to change event to handle form validity for Hosted Fields
        hostedInstance.on('change', (data) => {
          dispatchValidity(data.valid);
        });
      } else {
        // Listen to paymentProductChange to always disable payment button onChange
        hostedInstance.on('paymentProductChange', () => {
          dispatchValidity(false);
        });

        // Listen to validityChange to handle form validity for Hosted Payments
        hostedInstance.on('validityChange', (data) => {
          dispatchValidity(data.valid);
        });

        hostedInstance.on('paymentAuthorized', function (data) {
          if (
            hostedInstance.currentPaymentProduct === 'paypal' ||
            hostedInstance.type === 'paypal'
          ) {
            dispatchStore({
              type: 'GET_PAYMENT_DATA',
              paymentData: data
            });
          }
        });

        if (hostedInstance.isList) {
          hostedInstance.on('paymentData', async (data) => {
            dispatchStore({
              type: 'GET_PAYMENT_DATA',
              paymentData: data
            });
          });
        }
      }
    }
  }, [hostedInstance, dispatchStore]);

  // Use effect when Hosted Instance is created to add listeners
  useEffect(() => {
    initHostedInstanceEvents();
  }, [initHostedInstanceEvents]);

  const contextValue = useMemo(
    () => ({
      hostedConfig,
      hostedInstance,
      initHostedInstance,
      destroyHostedInstance
    }),
    [destroyHostedInstance, hostedConfig, hostedInstance, initHostedInstance]
  );

  // Render provider with instance, init & destroy exposed
  return (
    <HostedInstanceContext.Provider value={contextValue}>
      {children}
    </HostedInstanceContext.Provider>
  );
};

// Hook function to use Hosted Instance from context
export function useHostedInstance() {
  const context = useContext(HostedInstanceContext);

  if (!context) {
    throw new Error(
      'useHostedInstance must be used within a HostedInstanceProvider'
    );
  }

  return context;
}
