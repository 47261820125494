/* eslint-disable sonarjs/no-nested-template-literals */
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import Status from '../../Enum/PageStatus';

import { usePayload, storeContext } from '../../contexts/store';
import { intlContext, translations } from '../../contexts/intl';
import { themeContext } from '../../contexts/theme';
import { HiPayProvider } from '../../contexts/hipay';
import { HostedInstanceProvider } from '../../contexts/hostedInstance';

import { loadHiPay } from '../../utils/hipayLoader';
import { validatePage } from '../../utils/api';
import { decodeToken } from '../../utils/checkToken';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FormContainer from '../FormContainer/FormContainer';
import PageStatus from '../../components/PageStatus/PageStatus';

import { HostedReferenceProvider } from '../../contexts/hostedReferenceInstance';
import ReferenceContainer from '../ReferenceContainer/ReferenceContainer';

// Interval between two pages revalidations. 600000 => 10min
const REVALIDATE_INTERVAL = 600000;

const PageContent = () => {
  const navigate = useNavigate();
  const { store, dispatchStore } = useContext(storeContext);
  const { dispatchLocale } = useContext(intlContext);
  const { dispatchTheme } = useContext(themeContext);

  const { token_url, status } = store;
  const payload = usePayload();

  // Ref to HiPay SDK
  let hipayPromise = useRef(null);

  useEffect(() => {
    const getPageData = async () => {
      try {
        // Call API to check page status
        const {
          token_page,
          status,
          transaction_additional_informations,
          api_data_id
        } = await validatePage(token_url);

        // Decode token_page
        const payload = decodeToken(token_page);
        // Set page title
        if (payload?.customization?.merchant?.merchant_display_name) {
          document.title = payload.customization.merchant.merchant_display_name;
        }

        // Parse language to fit our format
        // en_US ==> en
        let lang = window.navigator.language.slice(0, 2) || 'en';
        let payloadLang = payload?.request?.language
          ?.slice(0, 2)
          ?.toLowerCase();
        if (translations.hasOwnProperty(payloadLang)) {
          lang = payloadLang;
        }

        // Init HiPay SDK
        hipayPromise.current = loadHiPay({
          username: payload.public_credentials.username,
          password: payload.public_credentials.password,
          lang: lang,
          environment: process.env.REACT_APP_HIPAY_ENV,
          data_id: api_data_id
        });

        // Update locale
        dispatchLocale({
          type: 'UPDATE_INTL',
          language: lang,
          currency: payload.request.currency
        });

        // Save customize object in theme
        // Update theme from payload
        dispatchTheme({
          type: 'UPDATE_CUSTOMIZATION',
          customization: { ...payload.customization }
        });

        // Save page data
        dispatchStore({
          type: 'SAVE_PAGE_DATA',
          token_page,
          payload,
          status,
          transaction_additional_informations
        });

        setInterval(async () => {
          const { status, retry } = await validatePage(token_url);

          dispatchStore({
            type: 'UPDATE_PAGE_STATUS',
            status,
            retries: retry,
            forward_url: payload?.request?.exception_url
          });
        }, REVALIDATE_INTERVAL);
      } catch (err) {
        // If page not exists, API error
        console.error(err);
        navigate('/404');
      }
    };
    // When token page init, check it and get payload
    if (token_url) {
      getPageData();
    }
  }, [token_url, dispatchStore, dispatchLocale, dispatchTheme, navigate]);

  if (!payload) {
    return null;
  }

  return (
    <StyledPageContent>
      <Header className="Header" />

      <div className="content">
        {(() => {
          switch (status) {
            case Status.FORWARDING:
            case Status.PENDING:
              return (
                <HiPayProvider hipay={hipayPromise.current}>
                  <HostedInstanceProvider>
                    <FormContainer />
                  </HostedInstanceProvider>
                </HiPayProvider>
              );
            case Status.PAID:
              return (
                <PageStatus
                  state="accepted"
                  status={status}
                  textSecondary="already-paid"
                />
              );
            case Status.FAILED:
              return (
                <PageStatus
                  state="rejected"
                  status={status}
                  textSecondary="failed"
                  forward_url={store.forward_url}
                />
              );
            case Status.EXPIRED:
              return (
                <PageStatus
                  state="rejected"
                  status={status}
                  textSecondary="expired"
                  forward_url={store.forward_url}
                />
              );
            case 'completed':
            case 'pending':
              return (
                <PageStatus
                  state="accepted"
                  status={status}
                  forward_url={store.forward_url}
                />
              );
            case Status.PENDING_REFERENCE:
            case 'pending_reference':
              return (
                <HiPayProvider hipay={hipayPromise.current}>
                  <HostedReferenceProvider>
                    <ReferenceContainer forwardUrl={store.forward_url} />
                  </HostedReferenceProvider>
                </HiPayProvider>
              );
            default:
              return (
                <PageStatus
                  state="rejected"
                  status="internal-error"
                  forward_url={store.forward_url}
                />
              );
          }
        })()}
      </div>

      <Footer className="Footer" status={status} />
    </StyledPageContent>
  );
};

const StyledPageContent = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;

  .Header {
    flex-shrink: 0;

    ${({ theme }) => theme.content.type === 'iframe' && `display: none`}
  }

  .content {
    height: 100%;
    flex: 1 0 1px;
    overflow: hidden;
    margin-bottom: 24px;
  }

  .Footer {
    ${({ theme }) => theme.content.type === 'iframe' && `display: none`}
  }
`;

export default PageContent;
