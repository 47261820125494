/* eslint-disable sonarjs/cognitive-complexity */
import { getAvailablePaymentProducts } from '../utils/api';

const cardTypes = [
  'visa',
  'mastercard',
  'cb',
  'american-express',
  'maestro',
  'bcmc'
];

const sddProperties = ['gender', 'firstname', 'lastname', 'bank_name', 'iban'];
const bicPaymentProducts = ['giropay', 'ideal'];
const phonePaymentProducts = ['mbway'];
const emailPaymentProducts = ['payshop'];

// Check if list of payment_products has only card elements
// Returns boolean
export const hasOnlyCard = (array) => {
  if (!Array.isArray(array)) {
    throw new Error('hasOnlyCard method requires array param');
  }

  return array.every((el) => cardTypes.includes(el));
};

// Filter list and return only cards payments products
// Returns an array
export const extractCardsProducts = (array) => {
  if (!Array.isArray(array)) {
    throw new Error('extractCardsProducts method requires array param');
  }

  return array.filter((el) => cardTypes.includes(el));
};

// Convert payment product list to array
export const productListToArray = (list) => {
  if (!list) {
    return [];
  }
  if (typeof list !== 'string') {
    throw new Error('extractCardsProducts method requires string param');
  }

  return list
    .split(',')
    .map((el) => el.trim())
    .filter((el) => el.length);
};

// Transform hpayment request object into Hosted Fields/Payments init options
export const getOptionsFromRequest = async (
  request,
  customization,
  type,
  client
) => {
  // Convert to array
  const payment_product_list = productListToArray(request.payment_product_list);

  // Init default object
  let options = {
    isPaymentPageV2: true,
    type: customization?.content?.style || 'carousel',
    carousel: {
      payment_product: payment_product_list,
      currency: request.currency || '',
      country: request.country || ''
    },
    list: {
      styles: {
        base: {
          iconColor: '#181818'
        }
      },
      payment_product: payment_product_list,
      currency: request.currency || '',
      country: request.country || ''
    },
    card: {
      multi_use: !!request.multi_use,
      fields: {}
    },
    sdd: {
      fields: {}
    },
    mbway: {
      fields: {}
    },
    payshop: {
      fields: {}
    },
    paypal: {
      canPayLater:
        typeof request.paypal_v2_bnpl === 'undefined'
          ? undefined
          : Boolean(request.paypal_v2_bnpl),
      paypalButtonStyle: {
        label: request.paypal_v2_label,
        shape: request.paypal_v2_shape,
        color: request.paypal_v2_color,
        height: request.paypal_v2_height
      },
      request: {
        amount: Number(request.amount),
        currency: request.currency,
        locale: request.language
      }
    }
  };

  // Add card options
  if (request?.firstname && request?.lastname) {
    options.card.fields.cardHolder = {
      defaultFirstname: request.firstname,
      defaultLastname: request.lastname
    };
  }
  // Limit card brands accepted
  if (request?.payment_product_list) {
    const cardBrands = extractCardsProducts(payment_product_list);
    // If we have a card type (i.e HostedFields), we fetch available payment products
    let filteredCardBrands = [...cardBrands];
    if (type === 'card') {
      const availables_product = await getAvailablePaymentProducts(
        client,
        request.country || '',
        request.currency || '',
        cardBrands
      );
      filteredCardBrands = availables_product
        .filter((product) => cardBrands.includes(product.code))
        .map((product) => product.code);
    }

    // Add parameter only if cards accepted
    if (cardBrands.length > 0) {
      options.card.brand = filteredCardBrands;
    }
  }

  // Add sdd options
  sddProperties.forEach((prop) => {
    if (request.hasOwnProperty(prop)) {
      options.sdd.fields[`${prop}`] = {
        defaultValue: request[`${prop}`]
      };
    }
  });

  // Add issuer_bank_id properties
  if (request?.issuer_bank_id) {
    bicPaymentProducts.forEach((product) => {
      options[`${product}`] = {
        fields: {
          issuer_bank_id: {
            defaultValue: request.issuer_bank_id
          }
        }
      };
    });
  }

  // Add phone properties
  if (request?.phone) {
    phonePaymentProducts.forEach((product) => {
      options[`${product}`] = {
        fields: {
          phone: {
            defaultValue: request.phone
          }
        }
      };
    });
  }

  // Add email properties
  if (request?.email) {
    emailPaymentProducts.forEach((product) => {
      options[`${product}`] = {
        fields: {
          email: {
            defaultValue: request.email
          }
        }
      };
    });
  }

  return type ? options[`${type}`] : options;
};

// Get options from request
// Get only Hosted Fields options if only card form to display
export const getHostedOptions = async (request, customization, client) => {
  // Convert to array
  const payment_product_list = productListToArray(request.payment_product_list);

  if (payment_product_list.length && hasOnlyCard(payment_product_list)) {
    return Promise.resolve({
      type: 'card',
      options: {
        ...(await getOptionsFromRequest(request, customization, 'card', client))
      }
    });
  } else {
    return Promise.resolve({
      type: 'hosted-payments',
      options: await getOptionsFromRequest(request, customization)
    });
  }
};
