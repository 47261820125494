import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Logo404 from '../../components/Logo404/Logo404';

const Page404 = ({ className }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <div className="title">
        <FormattedMessage id="404-title" />
      </div>

      <Logo404 className="logo" />

      <p>
        <FormattedMessage id="404-error-message-1" />
        <br />
        <FormattedMessage id="404-error-message-2" />
      </p>

      <div className="button-container">
        {window.history.state && window.history.state.idx > 1 && (
          <Button
            aria-label="go-back"
            data-testid="go-back-button"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={() => {
              navigate(-2);
            }}
          >
            <FormattedMessage id="404-button-text" />
          </Button>
        )}
      </div>
    </div>
  );
};

const StyledPage404 = styled(Page404)`
  width: 33%; // [4/12]
  margin: auto;
  padding: 48px;
  overflow: hidden;

  background-color: #ffffff;
  text-align: center;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    sans-serif;

  .title {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .logo {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  .button-container {
    margin: 56px 16px 0 16px;
  }

  .MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;

    &:hover {
      background-color: rgb(0, 120, 154);
    }
  }

  /* SM */
  ${({ theme }) => theme.breakpoints.only('sm')} {
    width: 75%; // [8/12]
    padding: 64px 12.5vw;

    .title {
      font-size: 28px;
      margin-bottom: 48px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }

    .button-container {
      margin: 72px 16px 0 16px;
    }

    .MuiButtonBase-root {
      height: 64px;
      border-radius: 33px;

      font-size: 16px;
    }
  }

  /* XS */
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    padding: 16px;

    .title {
      margin-bottom: 32px;
    }
  }

  /* XL */
  @media screen and (min-width: 1921px) {
    width: 25%; // [3/12]
    padding: 72px;
  }
`;

export default StyledPage404;
