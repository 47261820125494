import { createContext, useReducer } from 'react';
import { IntlProvider } from 'react-intl';

import de from '../i18n/de';
import en from '../i18n/en';
import es from '../i18n/es';
import fr from '../i18n/fr';
import it from '../i18n/it';
import pt from '../i18n/pt';
import nl from '../i18n/nl';

// Translations keys by language
const translations = {
  de,
  en,
  es,
  fr,
  it,
  pt,
  nl
};

// Get navigator language
const navigatorLanguage = window.navigator.language.slice(0, 2);
// Set default language
const defaultLanguage = translations.hasOwnProperty(navigatorLanguage)
  ? navigatorLanguage
  : 'en';

const defaultCurrency = 'USD';
// Formats are used by components to display currencies by adding format="currency"
const defaultFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: defaultCurrency
    }
  }
};

const initialState = {
  language: defaultLanguage,
  currency: defaultCurrency,
  formats: defaultFormats
};

const intlContext = createContext();
const { Provider } = intlContext;

const I18nProvider = ({ children }) => {
  const [state, dispatchLocale] = useReducer(reducer, initialState);

  return (
    <Provider value={{ dispatchLocale }}>
      <IntlProvider
        locale={state.language}
        messages={{
          ...translations['en'],
          ...translations[`${state.language}`]
        }}
        defaultLocale="en"
        formats={state.formats}
        defaultFormats={state.formats}
      >
        {children}
      </IntlProvider>
    </Provider>
  );
};

const reducer = (state, action) => {
  if (action.type === 'UPDATE_INTL') {
    let newState = { ...state };

    // Set locale
    if (translations.hasOwnProperty(action.language)) {
      newState.language = action.language;
    }

    // Set currency to display
    if (action.currency) {
      newState.currency = action.currency;
      newState.formats = {
        number: {
          currency: {
            style: 'currency',
            currency: action.currency
          }
        }
      };
    }

    return newState;
  }

  return state;
};

export { intlContext, I18nProvider, translations };
