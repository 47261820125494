import { createContext, useReducer, useContext } from 'react';

const storeContext = createContext();
const { Provider } = storeContext;

const DEFAULT_STATE = {
  formValidity: false,
  paymentData: null,
  isLoading: false
};

const StoreProvider = ({ initialState = DEFAULT_STATE, children }) => {
  const [store, dispatchStore] = useReducer(reducer, initialState);

  return <Provider value={{ store, dispatchStore }}>{children}</Provider>;
};

const reducer = (state, action) => {
  let newState;

  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      };
    case 'INIT_PAGE':
      return {
        ...state,
        token_url: action.token_url,
        token_url_payload: { ...action.payload }
      };
    case 'SAVE_PAGE_DATA':
      newState = {
        ...state,
        token_page: action.token_page,
        payload: action.payload,
        status: action.status
      };

      // Add reference if present
      if (
        action.transaction_additional_informations?.reference &&
        action.transaction_additional_informations?.payment_product
      ) {
        newState.reference =
          action.transaction_additional_informations.reference;
        newState.payment_product =
          action.transaction_additional_informations.payment_product;
      }

      return newState;
    case 'UPDATE_FORM_VALIDITY':
      return {
        ...state,
        formValidity: action.formValidity
      };
    case 'GET_PAYMENT_DATA':
      return {
        ...state,
        paymentData: action.paymentData
      };
    case 'UPDATE_PAGE_STATUS':
      // Update status
      newState = {
        ...state,
        status: action.status
      };
      // Add forwardUrl if present
      if (action?.forward_url) {
        newState.forward_url = action.forward_url;
      }
      // Add number of retries available if present
      if (action?.retries) {
        newState.retries = action.retries;
      }
      // Add referenceToPay
      if (action?.reference) {
        newState.reference = action.reference;
      }
      if (action?.payment_product) {
        newState.payment_product = action.payment_product;
      }
      return newState;
    default:
      return state;
  }
};

export const usePayload = () => {
  const { store } = useContext(storeContext);

  const { payload } = store;

  return payload;
};

export { storeContext, StoreProvider };
