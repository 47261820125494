import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  box-sizing: border-box;
  height: -webkit-fill-available;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@media print {
  * {
    visibility: hidden;
  }
}

body {
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${({ theme }) => theme.typography.fontFamily.join(',')};
}

html,
  body,
  #root {
  overflow: auto;
}

#root {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex;
}

[data-hipay-id='hostedfield-iframe'][id*='hipay-controller'] {
  position: absolute;
} 
`;

export default GlobalStyles;
