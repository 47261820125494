import { ReactComponent as SpinnerSvg } from './Spinner.svg';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Spinner = styled(({ animate, ...rest }) => <SpinnerSvg {...rest} />)`
  width: ${(props) => props.width || '120px'};
  height: ${(props) => props.height || '120px'};

  animation: ${(props) =>
    props.animate
      ? 'rotate 1s cubic-bezier(0.3, 1, 0.6, 0.5) infinite'
      : 'none'};

  .circle_stroke {
    stroke-dasharray: 200;
    stroke-dashoffset: -120;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

Spinner.propTypes = {
  animate: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

Spinner.defaultProps = {
  animate: true
};

export default Spinner;
