import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CrossIconLite } from './../CrossIcon/CrossIconLite.svg';

const InlineErrorComponent = (props) => {
  return (
    <div className={props.className}>
      <div className="text-container">
        <CrossIconLite height="32px" width="32px" />
        <Typography variant="body1" color="error" className="text">
          <FormattedMessage id={`status-text-error`} />{' '}
          <FormattedMessage id={`error-text-retry`} />
        </Typography>
      </div>

      {props.retries && props.retries <= 3 && (
        <Typography
          variant="body1"
          color="textSecondary"
          className="attempts-remaining"
        >
          <FormattedMessage id={`attempts-remaining`} />
          {': '}
          {props.retries}
        </Typography>
      )}
    </div>
  );
};

const InlineError = styled(InlineErrorComponent)`
  margin-top: 32px;

  .text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text {
    margin-left: 8px;
  }

  .attempts-remaining {
    text-align: center;
    margin-top: 8px;
  }
`;

InlineError.propTypes = {
  retries: PropTypes.number
};

export default InlineError;
