import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { storeContext } from '../../contexts/store';
import { themeContext } from '../../contexts/theme';

import { verifyAndDecodeToken } from '../../utils/checkToken';

import PageContent from '../PageContent/PageContent';

import Background from './Background.jpg';

const stage_url = 'https://stage-pay.hipay.com';
const production_url = 'https://pay.hipay.com';

// In stage and production, do not accept default App Engine urls
// Return true if origin is OK
const checkPageOrigin = () => {
  const env = process.env.REACT_APP_HIPAY_FRONT_ENV;
  const origin = window.location.origin;

  if (env === 'stage') {
    return origin === stage_url;
  } else if (env === 'production') {
    return origin === production_url;
  } else {
    return true;
  }
};

const Page = () => {
  const navigate = useNavigate();

  // Get URL params
  const { merchant_id, token_url } = useParams();

  // Get context actions
  const { store, dispatchStore } = useContext(storeContext);
  const { dispatchTheme } = useContext(themeContext);

  useEffect(
    () => {
      const verifyToken = async () => {
        let token_url_payload;
        try {
          token_url_payload = await verifyAndDecodeToken(token_url);
        } catch (err) {
          navigate('/404');
        }
        if (token_url_payload) {
          // Check if URL merchant ID equals token merchant ID
          if (merchant_id !== token_url_payload.mid) {
            navigate('/404');
          }

          // Save payload in store
          dispatchStore({
            type: 'INIT_PAGE',
            token_url,
            token_url_payload
          });

          if (token_url_payload.customization?.content) {
            // Update theme from payload
            dispatchTheme({
              type: 'UPDATE_CUSTOMIZATION',
              customization: { ...token_url_payload.customization }
            });
          }
        }
      };

      verifyToken();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Check page origin
  if (!checkPageOrigin()) {
    navigate('/404');
    return null;
  }

  if (!store.token_url_payload) {
    return null;
  }

  return (
    <StyledPageContainer>
      <StyledContentContainer>
        <PageContent />
      </StyledContentContainer>
      <StyledBackground />
    </StyledPageContainer>
  );
};

const getCustomizationContentClasses = (theme) => {
  if (theme?.content) {
    return `content-${theme.content.type} content-${theme.content.position}`;
  }
};

export const StyledBackground = styled('div').attrs(({ theme }) => ({
  className: getCustomizationContentClasses(theme),
  backgroundImage: theme.merchant
    ? theme.merchant.background
      ? `url(${theme.merchant.background})`
      : `url(${Background})`
    : `none`
}))`
  z-index: 0;

  background-color: #f5f5f5;
  background-image: ${({ backgroundImage }) => backgroundImage};

  background-repeat: no-repeat;
  background-size: cover;

  &.content-iframe  {
    background: none;
  }

  &.content-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.content-full {
    flex: auto;
    background-position: center;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledPageContainer = styled('div').attrs(({ theme }) => ({
  className: getCustomizationContentClasses(theme)
}))`
  display: flex;
  width: 100%;

  &.content-iframe {
    justify-content: center;
  }

  &.content-full {
    &.content-left {
      flex-direction: row;
    }
    &.content-right {
      flex-direction: row-reverse;
    }
  }

  &.content-modal {
    justify-content: ${({ theme }) =>
      theme.content && theme.content.position === 'left'
        ? 'flex-start'
        : theme.content && theme.content.position === 'right'
        ? 'flex-end'
        : 'center'};

    ${({ theme }) => theme.breakpoints.up('md')} {
      &.content-left {
        margin-left: 80px;
      }
      &.content-right {
        margin-right: 80px;
      }
    }
  }
`;

export const StyledContentContainer = styled('div').attrs(({ theme }) => ({
  className: getCustomizationContentClasses(theme)
}))`
  width: 100%;
  margin: 0;
  padding: 32px 56px;

  z-index: 100;
  background-color: #ffffff;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 24px 20px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 60%;

    &.content-modal {
      margin: 24px;
      padding: 32px;
      border-radius: 12px;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
    }

    &.content-full {
      padding: 56px;

      &.content-left {
        padding-left: 80px;
      }
      &.content-right {
        padding-right: 80px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 42%;
  }

  @media (min-width: 1921px) {
    max-width: 800px;

    &.content-modal {
      margin: 120px;
      border-radius: 16px;
    }
  }
`;

export default Page;
