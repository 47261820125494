import { importSPKI, jwtVerify, decodeJwt } from 'jose';

const public_key = process.env.REACT_APP_HIPAY_SIGN_PUBLIC_KEY;

export const verifyAndDecodeToken = async (token) => {
  const ecPublicKey = await importSPKI(public_key, 'RS256');
  const { payload } = await jwtVerify(token, ecPublicKey);
  return payload;
};

export const decodeToken = (token) => {
  return decodeJwt(token);
};
