import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { StoreProvider } from './contexts/store';
import { ThemeProvider } from './contexts/theme';
import { I18nProvider } from './contexts/intl';

import Page404 from './containers/Page404/Page404';
import Page from './containers/Page/Page';
import PageReference from './containers/PageReference/PageReference';

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <I18nProvider>
          <Routes>
            <Route
              path="/:merchant_id/:token_url"
              element={
                <StoreProvider>
                  <Page />
                </StoreProvider>
              }
            />
            <Route
              path="/reference/:payment_product/:merchant_id/:transaction_id"
              element={
                <StoreProvider>
                  <PageReference />
                </StoreProvider>
              }
            ></Route>
            <Route path="/404" element={<Page404 />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </I18nProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
