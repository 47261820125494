const SDK_URL =
  process.env.REACT_APP_HIPAY_SDK_JS || 'https://libs.hipay.com/js/sdkjs.js';

// Inject HiPay SDK in page
const injectScript = () => {
  const script = document.createElement('script');
  script.src = SDK_URL;

  const container = document.head || document.body;

  if (!container) {
    throw new Error(
      'HiPay requires a <head> or <body> element to be injected in.'
    );
  }

  container.appendChild(script);

  return script;
};

// Returns HiPay SDK
let hipayPromise = Promise.resolve().then(() => {
  if (typeof window === 'undefined') {
    return null;
  }

  // If already loaded
  if (window.HiPay) {
    return window.HiPay;
  }

  // Else inject in page
  const script =
    document.querySelector(`script[src="${SDK_URL}"]`) || injectScript();

  // Return Promise that resolve loaded SDK
  return new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      if (window.HiPay) {
        resolve(window.HiPay);
      } else {
        reject(new Error('Failed to load HiPay'));
      }
    });

    script.addEventListener('error', () => {
      reject(new Error('Failed to load HiPay'));
    });
  });
});

// Get HiPay and init
export const loadHiPay = (...args) =>
  hipayPromise.then((hipay) => (hipay ? hipay(...args) : null));
